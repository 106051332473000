import {
  BrowserRouter as Router,
  Route,
  Routes,
  useMatch,
} from "react-router-dom";
import { theme, darkMode } from "cdk-radial";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { Main } from "pages/Main";
import { findEntry } from "utils/findEntry";
import { useSettings } from "store/useSettings";
import { useUser } from "store/useUser";
import { setAppTitle } from "utils/setAppTitle";
import { Login } from "pages/Login/Login";
import "./App.css";
import { getFavoriteApps } from "utils/getFavoriteApps";
import { getFavoriteWorkflows } from "utils/getFavoriteWorkflows";
import { MyApplications } from "components/MyApplications";
import { MyWorkflows } from "components/MyWorkflows";
import { getAllApps } from "utils/getAllApps";
import { AllApplications } from "components/AllApplications";
import { AllWorkflows } from "components/AllWorkflows";
import { getAllWorkflows } from "utils/getAllWorkflows";
import { NewOrg } from "components/NewOrg";
import { NewOrgAlt } from "components/NewOrgAlt";

const Pane = styled.div`
  display: none;
  &.show {
    display: block;
  }
`;

const GlobalStyles = createGlobalStyle`
  body { 
    font-family: "Montserrat";
    margin:0; 
    padding:0; 
  }
  html { color-scheme: light dark;}
`;

const StyledApplication = styled(({ className }) => {
  const match = useMatch(":app");
  const entry = findEntry({
    name: decodeURIComponent(match.params.app),
  });
  return (
    <div className={className}>
      <img src={entry.image} width={entry.width} alt="" />
    </div>
  );
})`
  img {
    display: flex;
    margin: auto;
    aspect-ratio: auto;
  }
`;

const Application = (props) => <StyledApplication {...props} />;

const MyUnify = () => {
  const user = useUser((state) => state.users[state.currentUser]);

  const favoriteApps = getFavoriteApps(user);
  const favoriteWorkflows = getFavoriteWorkflows(user);

  return (
    <Pane className={"MyUnify show"}>
      {favoriteApps && <MyApplications appsList={favoriteApps} />}
      {favoriteWorkflows && <MyWorkflows workflowsList={favoriteWorkflows} />}
    </Pane>
  );
};

const Applications = styled(({ className }) => {
  const allApps = getAllApps();
  return (
    <Pane className={`Applications ${className} show`}>
      <AllApplications list={allApps} />
    </Pane>
  );
})``;

const Workflows = styled(({ className }) => {
  const allWorkflows = getAllWorkflows();
  return (
    <Pane className={`Workflows ${className} show`}>
      <AllWorkflows list={allWorkflows} />
    </Pane>
  );
})``;

const NewOrgPane = styled(({ className }) => {
  return (
    <Pane className={`NewOrg ${className} show`}>
      <NewOrg />
    </Pane>
  );
})``;
const NewOrgPaneAlt = styled(({ className }) => {
  return (
    <Pane className={`NewOrgAlt ${className} show`}>
      <NewOrgAlt />
    </Pane>
  );
})``;

const App = styled(({ className }) => {
  setAppTitle();
  const isDarkMode = useSettings((state) => state.isDarkMode);
  return (
    <ThemeProvider theme={isDarkMode ? darkMode : theme} className={className}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Main />}>
            <Route path="neworg" element={<NewOrgPane />} />
            <Route path="neworgalt" element={<NewOrgPaneAlt />} />
            <Route path="myunify" element={<MyUnify />} />
            <Route path="applications" element={<Applications />} />
            <Route path="workflows" element={<Workflows />} />
          </Route>
          <Route path="/app" element={<Main />} />

          <Route path=":app" element={<Application />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
})`
  height: 100vh;
  .SideSheetOverlay {
    background: transparent;
  }
`;

export default App;
